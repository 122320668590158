import React from 'react';

const Footer = () => {
    return (
        <div className="app-footer">
            <p>© 2024 Geolabs, Inc. All Rights Reserved.</p>
            <p>Designed by Taiki Owen Yamashita</p>
        </div>
    );
};

export default Footer;